<template>
    <Layout>
      <template v-if="this.$route.name == 'add-campaign-speciality-data'">
        <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
        <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body" id="medinext">
              <b-form enctype="multipart/form-data" ref="promotion">
                <div class="row">
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_title">Campaign Speciality Name <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_name"
                        placeholder="Enter Campaign Speciality Name"
                        v-model="form.name"
                        :class="{
                          'is-invalid': submitted && $v.form.name.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                            Campaign Speciality Name is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_title">Campaign Speciality key <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_key"
                        placeholder="Enter Campaign Speciality key"
                        v-model="form.key"
                        :class="{
                          'is-invalid': submitted && $v.form.key.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.key.required" class="invalid-feedback">
                            Campaign Speciality key is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_speciality_marathi_name">Campaign Speciality Marathi Name <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_marathi_name"
                        placeholder="Enter Speciality Marathi Name"
                        v-model="form.marathi_name"
                        :class="{
                          'is-invalid': submitted && $v.form.marathi_name.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.marathi_name.required" class="invalid-feedback">
                            Campaign Speciality Marathi Name is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_speciality_hindi_name">Campaign Speciality Hindi Name <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_hindi_name"
                        placeholder="Enter Speciality Hindi Name"
                        v-model="form.hindi_name"
                        :class="{
                          'is-invalid': submitted && $v.form.hindi_name.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.hindi_name.required" class="invalid-feedback">
                            Campaign Speciality Hindi Name is required.
                        </div>
                    </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Icon</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="icon"
                      @change="readFile($event, 'icon')"
                      ref="icon"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-speciality-data' && edit.icon_img_url"
                    >
                      <img
                        :src="edit.icon_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="icon_img_url">
                      <img
                        :src="icon_img_url"
                        width="128px"
                        height="128px"
                        ref="camp_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Border Icon</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="border_icon"
                      @change="readFile($event, 'border_icon')"
                      ref="border_icon"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-speciality-data' && edit.border_icon_img_url"
                    >
                      <img
                        :src="edit.border_icon_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="border_icon_img_url">
                      <img
                        :src="border_icon_img_url"
                        width="128px"
                        height="128px"
                        ref="border_icon_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Border Icon 2</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="border_icon2"
                      @change="readFile($event, 'border_icon2')"
                      ref="border_icon2"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-speciality-data' && edit.border_icon2_img_url"
                    >
                      <img
                        :src="edit.border_icon2_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="border_icon2_img_url">
                      <img
                        :src="border_icon2_img_url"
                        width="128px"
                        height="128px"
                        ref="border_icon2_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Vertical Background Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="ver_background_image"
                      @change="readFile($event, 'ver_background_image')"
                      ref="ver_background_image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-speciality-data' && edit.ver_background_image_img_url"
                    >
                      <img
                        :src="edit.ver_background_image_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="ver_background_image_img_url">
                      <img
                        :src="ver_background_image_img_url"
                        width="128px"
                        height="128px"
                        ref="ver_background_image_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Horizontal Background Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="hor_background_image"
                      @change="readFile($event, 'hor_background_image')"
                      ref="hor_background_image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-speciality-data' && edit.hor_background_image_img_url"
                    >
                      <img
                        :src="edit.hor_background_image_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="hor_background_image_img_url">
                      <img
                        :src="hor_background_image_img_url"
                        width="128px"
                        height="128px"
                        ref="hor_background_image_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Card2 Background Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="card2_background_image"
                      @change="readFile($event, 'card2_background_image')"
                      ref="card2_background_image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-speciality-data' && edit.card2_background_image_img_url"
                    >
                      <img
                        :src="edit.card2_background_image_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="card2_background_image_img_url">
                      <img
                        :src="card2_background_image_img_url"
                        width="128px"
                        height="128px"
                        ref="card2_background_image_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Card3 Background Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="card3_background_image"
                      @change="readFile($event, 'card3_background_image')"
                      ref="card3_background_image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-speciality-data' && edit.card3_background_image_img_url"
                    >
                      <img
                        :src="edit.card3_background_image_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="card3_background_image_img_url">
                      <img
                        :src="card3_background_image_img_url"
                        width="128px"
                        height="128px"
                        ref="card3_background_image_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Card4 Background Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="card4_background_image"
                      @change="readFile($event, 'card4_background_image')"
                      ref="card4_background_image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-speciality-data' && edit.card4_background_image_img_url"
                    >
                      <img
                        :src="edit.card4_background_image_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="card4_background_image_img_url">
                      <img
                        :src="card4_background_image_img_url"
                        width="128px"
                        height="128px"
                        ref="card4_background_image_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_title">Campaign Speciality Color <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_color"
                        placeholder="Enter Campaign Speciality Color"
                        v-model="form.color"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_title">Campaign Speciality Color 2 <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_color2"
                        placeholder="Enter Campaign Speciality Color"
                        v-model="form.color2"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  <span v-if="this.$route.name == 'add-campaign-speciality-data'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../../mixins/request";
  import mrappcampaignSpecDataMixin from "../../../../mixins/ModuleJs/campaign-speciality-data";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    data() {
      return {
        submitted: false,
        title1: "Add Campaign Speciality Data",
        title2: "Edit Campaign Speciaility Data",
        items: [
          {
            text: "List",
            href: "/field-rep-app/campaign-speciality-data",
          },
          {
            text: "Data",
          },
        ],
      };
    },
    mixins: [MixinRequest, mrappcampaignSpecDataMixin],
    components: {
      Layout,
      PageHeader
    },
    validations: {
      form: {
        name: { required },
        key: { required },
        marathi_name: { required },
        hindi_name: { required }
      },
    },
  };
  </script>
  