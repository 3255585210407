import {
    card,
    campaigns
  } from "../../js/path";
  
  export default {
    data() {
      return {
        pageNo: null,
        table_header: [],
        card_id: null,
        fields: [
          {
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: "title",
            label: "Title"
          },
          {
            key: "campaign_title",
            label: "Campaign"
          },
          {
            key: "is_active",
            label: "Status",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        params: "",
        filter: null,
        filterOn: [],
        tableData: [],
        dropdownSpeciality: [],
        sortBy: "id",
        sortDesc: true,
        key: 0,
        activeTab: "all",
        currentPage: 1,
        campaignDropdown: [],
        form: {
          title: "",
          campaign_id: "",
          campaign_title: "",
          poster_image:"",
          is_active: 0,
          dr_img_status: 0,
          spouse_status:0,
          preview_html_content: "",
          download_html_content: "",
          brand_speciality: "",
          type:""
        },
        poster_image: "",
        card_img_url: "",
        edit: {
          card_img_url: null,
        },
      };
    },
    computed: {
      
    },
    methods: {
      readFile(e, txt) {
        if (txt == "poster_image") {
          this.poster_image = e.target.files[0];
          if (this.poster_image.size > 5 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 5MB",
            });
            this.$refs.poster_image.reset();
            this.card_img_url = "";
            this.edit.card_img_url = "";
            this.poster_image = "";
            return false;
          } else {
            this.card_img_url = URL.createObjectURL(this.poster_image);
            this.edit.card_img_url = "";
            return true;
          }
        } 
      },
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      searchFor() {
        if (this.filter.length > 0) {
          this.fetchData(this.activeTab);
        } else {
          this.fetchData(this.activeTab);
        }
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
              card.mrAppcardUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = card.mrAppcardUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
              card.mrAppcardUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = card.mrAppcardUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
              card.mrAppcardUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = card.mrAppcardUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async updateStatus(id) {
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = card.mrAppcardupdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            is_active: !this.tableData.data[index].is_active,
          });
          if (data.status) {
            const responseData = data.response;
            this.tableData.data[index] = responseData;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
      },
      async fetchcard(id) {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = card.mrAppcardUrl + "/" + id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.title != "" && responseData.title != null) {
              this.form.title = responseData.title;
            }
            if (responseData.type != "" && responseData.type != null) {
              this.form.type = responseData.type;
            }
            if (
              responseData.campaign_title != "" &&
              responseData.campaign_title != null
            ) {
              this.form.campaign_title = responseData.campaign_title;
            }
            // if (
            //   responseData.campaign_id != "" &&
            //   responseData.campaign_id != null
            // ) {
            //   this.form.campaign_id = responseData.campaign_id;
            // }
            if (
              responseData.brand_speciality != "" &&
              responseData.brand_speciality != null
            ) {
              this.form.brand_speciality = responseData.brand_speciality;
            }
            if (responseData.is_active) {
              this.form.is_active = true;
            }
            if (responseData.dr_img_status) {
              this.form.dr_img_status = true;
            }
            if (responseData.spouse_status) {
              this.form.spouse_status = true;
            }
            if (responseData.poster_image != "" && responseData.poster_image != null) {
              this.edit.card_img_url = responseData.poster_image ? responseData.poster_image : "";
            }
            this.form.preview_html_content = responseData?.preview_html_content ?? "";
            this.form.download_html_content = responseData?.download_html_content ?? "";
            this.dropdownSpeciality = responseData.speciality_dropdown;
            this.card_id = responseData.id;
            this.form.campaign_id = {
              key: responseData?.campaign_id,
              label: responseData?.campaign_title
            }
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Fetching error!",
          });
        }
        this.$store.commit("toast/updateStatus", false);
      },
      async fetchCampaign() {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = campaigns.mrAppcampaignUrl + '?filter=active';
          const data = await this.getRequest(url);
          this.campaignDropdown = data.response.data.map((item) => ({
            key: item.id, // Value field
            label: item.title, // Text field
          }));
        } catch (error) {
          console.error('Error fetching dropdown data:', error);
        }
      },
      async deletecard(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = card.mrAppcardUrl + "/" + id;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async restorecard(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = card.restoremrAppcard + "/" + id;
          const data = await this.postRequest(url, {});
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            // window.scrollTo({
            //    top: 0,
            //    behavior: "smooth",
            // });
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          }
          this.$store.commit("loader/updateStatus", true);
          let url = card.mrAppcardUrl;
          if (this.$route.name == "edit-card") {
            url = card.mrAppcardUrl + "/" + this.card_id;
          }
          let dataAppend = new FormData();
  
          if (this.form.is_active) {
            dataAppend.append("is_active", 1);
          } else {
            dataAppend.append("is_active", 0);
          }

          if (this.form.dr_img_status) {
            dataAppend.append("dr_img_status", 1);
          } else {
            dataAppend.append("dr_img_status", 0);
          }

          if (this.form.spouse_status) {
            dataAppend.append("spouse_status", 1);
          } else {
            dataAppend.append("spouse_status", 0);
          }
  
          if (this.poster_image) {
            dataAppend.append("poster_image", this.poster_image);
          }

          if (this.form.campaign_id) {
            // console.log(this.form.campaign_id);
            dataAppend.append("campaign_id", this.form.campaign_id.key);
          }

          if (this.form.type) {
            console.log(this.form.type);
            dataAppend.append("type", this.form.type);
          }
  
          for (var key in this.form) {
            if (
              key != "is_active" &&
              key != "poster_image" &&
              key != "campaign_id" &&
              key != "campaign_title" &&
              key != "dr_img_status" &&
              key != "spouse_status"
            ) {
              dataAppend.append(key, this.form[key]);
            }
          }
  
          if (this.$route.name == "edit-card") {
            dataAppend.append("_method", "put");
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/field-rep-app/card");
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
    //   tabActive() {
    //     if (this.activeTab == "trash") {
    //        if (this.table_header.includes("delete")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           this.fields[index] = {
    //              key: "restore",
    //           };
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "delete"
    //           );
    //           this.table_header[table_index] = "restore";
    //        } else {
    //           this.table_header.push("restore");
    //           this.fields.push({
    //              key: "restore",
    //           });
    //        }
  
    //        if (!this.can("restore-card")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "restore"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "restore"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        }
  
    //        let index = this.fields.findIndex((item) => item.key == "edit");
    //        if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "edit"
    //           );
    //           this.table_header.splice(table_index, 1);
    //        }
    //     } else {
    //        if (this.table_header.includes("restore")) {
    //           if (!this.can("edit-card")) {
    //              this.fields[6] = {
    //                 key: "delete",
    //              };
    //           } else {
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
  
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "restore"
    //           );
    //           this.table_header[table_index] = "delete";
    //        }
  
    //        if (!this.can("edit-card")) {
    //           let index = this.fields.findIndex((item) => item.key == "edit");
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "edit"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("edit")) {
    //              this.table_header.push("edit");
    //              this.fields[6] = {
    //                 key: "edit",
    //              };
    //           }
    //        }
  
    //        if (!this.can("delete-card")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "delete"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("delete")) {
    //              this.table_header.push("delete");
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
    //        }
    //     }
    //  },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      // activeTab(v) {
        // if (v) this.tabActive();
      // },
      "form.content_type"(v) {
        if (v == "pop_up") {
          this.form.ad_position = 1;
        }
      },
    },
    created() {
      if (
        this.$route.name == "add-card" ||
        this.$route.name == "edit-card"
      ) {
        if (this.$route.name == "edit-card") {
          this.fetchcard(this.$route.params.id);
          this.fetchCampaign();
        }
      } else {
        this.fetchData(this.activeTab);
        
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      // this.tabActive();
      this.fetchCampaign();
    },
  };
  