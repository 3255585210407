var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-post')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row",attrs:{"id":"addpost"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form-wizard',{ref:"post",attrs:{"color":"#556ee6","enctype":"multipart/form-data","finishButtonText":this.$route.name == 'add-post' ? 'Save Data' : 'Update Data',"back-button-text":"Go Back!","next-button-text":"Go Next!"},on:{"on-complete":_vm.submitData}},[_c('tab-content',{attrs:{"title":"Post Details","icon":"mdi mdi-account-details","before-change":() => _vm.validateFormOne()}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12"},[(_vm.$route.name == 'add-post')?_c('label',{attrs:{"for":"pl"}},[_vm._v("Choose Post Type "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_vm._e(),_c('b-form-radio-group',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.action_type.$invalid,
                    },attrs:{"id":"radio-group-1","options":_vm.actionType,"name":"radio-options"},on:{"change":_vm.changeActionType},model:{value:(_vm.form.action_type),callback:function ($$v) {_vm.$set(_vm.form, "action_type", $$v)},expression:"form.action_type"}}),(_vm.submitted && !_vm.$v.form.action_type.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Post Type is required. ")]):_vm._e()],1),_c('div',{staticClass:"col-12"},[(_vm.form.action_type == 'post_an_update')?_c('label',[_vm._v("Post Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]):_c('label',[_vm._v("Presentation Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"w-100 form-control",class:{
                        'is-invalid': _vm.submitted && _vm.$v.form.title.$invalid,
                      },attrs:{"type":"text","placeholder":_vm.form.action_type == 'post_an_update'
                          ? 'Enter Post Title'
                          : 'Enter Presentation Title'},domProps:{"value":(_vm.form.title)},on:{"keyup":function($event){$event.preventDefault();return _vm.slugify.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "title", $event.target.value)}}}),(_vm.submitted && !_vm.$v.form.title.required)?_c('div',{staticClass:"invalid-feedback mb-2"},[(_vm.form.action_type == 'post_an_update')?_c('span',[_vm._v("Post Title is required.")]):_c('span',[_vm._v("Presentation Title is required.")])]):_vm._e()])]),_c('b-form-group',{staticClass:"col-6 mt-3"},[_c('label',{attrs:{"for":"slug"}},[_vm._v("Slug "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.slug.$invalid,
                    },attrs:{"id":"slug","placeholder":"Enter Slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}}),(_vm.submitted && !_vm.$v.form.slug.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Slug is required. ")]):_vm._e()],1),(_vm.form.action_type == 'presentation')?_c('b-form-group',{staticClass:"col-6 mt-3",attrs:{"label-for":"ppt"}},[_c('label',[_vm._v("Add Presentation [Upload .ppt and .pptx files] [Upload Max File Size : 50 MB]")]),_c('b-form-file',{ref:"case_ppt",attrs:{"id":"case_ppt","accept":".ppt, .pptx","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'file_name')}}})],1):_vm._e(),(_vm.isPostUrl && _vm.form.action_type == 'post_an_update')?_c('div',{staticClass:"col-6 mt-3"},[_c('b-form-group',[_c('label',[_vm._v("Post URL ")]),_c('div',{staticStyle:{"display":"grid","grid-template-columns":"1fr 0.2fr","grid-gap":"10px"}},[_c('b-form-input',{attrs:{"type":"text","id":"url_link","placeholder":"Enter Post URL"},on:{"change":_vm.changePostUrl},model:{value:(_vm.form.url_link),callback:function ($$v) {_vm.$set(_vm.form, "url_link", $$v)},expression:"form.url_link"}}),_c('b-button',{staticClass:"btn-info btn-sm",staticStyle:{"font-size":"18px"},attrs:{"disabled":!_vm.form.url_link,"id":"fetch-url"},on:{"click":_vm.fetchMetadata}},[_vm._v("Fetch URL")])],1)])],1):_vm._e(),_c('b-form-group',{staticClass:"col-12"},[_c('label',{attrs:{"for":"i3"}},[_vm._v("Description "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ckeditor',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.description.$invalid,
                    },attrs:{"editor":_vm.editor},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.submitted && !_vm.$v.form.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required. ")]):_vm._e()],1)],1),(_vm.form.action_type == 'post_an_update' && _vm.isImageVideo)?_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"case-img col-12"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{attrs:{"for":"case_image"}},[_vm._v("Add Image/Videos [Multiple Selection allowed] [Upload Max File Size : 50 MB]")]),(_vm.images_videos.length > 0)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.clearImageVideo}},[_c('img',{attrs:{"src":require("@/assets/images/close.png"),"height":"18px"}})]):_vm._e()]),_c('b-form-file',{ref:"post_images_videos",attrs:{"accept":"image/png, image/jpeg, image/jpg, video/mp4, video/x-m4v, video/avi, video/*,video/x-msvideo, .hevc, .h265, .mov, video/mov, video/quicktime","multiple":"","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'image_video')}},model:{value:(_vm.removeItem),callback:function ($$v) {_vm.removeItem=$$v},expression:"removeItem"}}),(
                      _vm.$route.name == 'edit-post' && _vm.images_videos.length > 0
                    )?[_c('div',{staticClass:"post_images"},[_vm._l((_vm.images_videos),function(item,index){return [_c('div',{key:index,staticStyle:{"position":"relative"},attrs:{"id":"delete-img"}},[(item.item_id)?_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.deleteImageVideo(
                                item.item_id,
                                index,
                                item.media_type
                              )}}},[(item.item_id)?_c('i',{staticClass:"mdi mdi-close-circle cross",style:({
                                top:
                                  item.media_type == 'image'
                                    ? '-9px'
                                    : '-12px',
                              })}):_vm._e()]):_vm._e(),(item.media_type == 'image')?_c('img',{key:index,attrs:{"src":item.image_name}}):_vm._e(),(item.media_type == 'video')?_c('video',{attrs:{"width":"300px","height":"180px","controls":""}},[_c('source',{key:index,attrs:{"src":item.image_name,"type":"video/mp4"}})]):_vm._e()])]})],2)]:_vm._e(),(
                      _vm.$route.name == 'add-post' && _vm.images_videos.length > 0
                    )?[_c('div',{staticClass:"post_images"},_vm._l((_vm.images_videos),function(item,index){return _c('div',{key:index},[(item.media_type == 'image')?_c('img',{key:index,attrs:{"src":item.image_name,"width":"128px","height":"128px"}}):_vm._e(),(item.media_type == 'video')?_c('video',{attrs:{"width":"320px","height":"200px","controls":""}},[_c('source',{key:index,attrs:{"src":item.image_name,"type":"video/mp4"}})]):_vm._e()])}),0)]:_vm._e()],2)],1):_vm._e(),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"label-for":"country"}},[_c('label',[_vm._v("Country ")]),_c('multiselect',{attrs:{"id":"input-multi","options":_vm.allCountry,"multiple":true,"track-by":"id","label":"name","placeholder":"Type here to search"},on:{"search-change":_vm.fetchAllCountry},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label-for":"community"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-2"},[_c('label',[_vm._v("Community "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-checkbox',{attrs:{"button":"","button-variant":"info","size":"sm"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[(_vm.status)?[_vm._v("Unselect All")]:[_vm._v("Select All")]],2)],1),_c('multiselect',{class:{
                      'is-invalid':
                        _vm.submitted && _vm.$v.form.communities_selected.$invalid,
                    },attrs:{"options":_vm.getCommunity,"multiple":true,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchCommunity},model:{value:(_vm.form.communities_selected),callback:function ($$v) {_vm.$set(_vm.form, "communities_selected", $$v)},expression:"form.communities_selected"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.communities_selected.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Community is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Sub Speciality","label-for":"video_sub_spec"}},[_c('multiselect',{attrs:{"id":"video_sub_spec","options":_vm.subspecialities,"multiple":true,"track-by":"id","label":"name","placeholder":"Type here to search"},model:{value:(_vm.form.sub_specialities),callback:function ($$v) {_vm.$set(_vm.form, "sub_specialities", $$v)},expression:"form.sub_specialities"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])])],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("Member "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('multiselect',{class:{
                      'is-invalid':
                        _vm.submitted && _vm.$v.form.member_tagging.$invalid,
                    },attrs:{"id":"member","options":_vm.memberList,"multiple":false,"track-by":"member_id","label":"fnameAndLname","placeholder":"Type here to search"},on:{"search-change":_vm.fetchMemberList},model:{value:(_vm.form.member_tagging),callback:function ($$v) {_vm.$set(_vm.form, "member_tagging", $$v)},expression:"form.member_tagging"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.member_tagging.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Member is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label-for":"meta_title"}},[_c('label',[_vm._v("Meta Title")]),_c('b-form-input',{attrs:{"type":"text","id":"meta_title","placeholder":"Enter Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label-for":"meta_keywords"}},[_c('label',[_vm._v("Meta Keywords")]),_c('b-form-input',{attrs:{"type":"text","id":"meta_keywords","placeholder":"Enter Meta Keywords"},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}})],1),_c('b-form-group',{staticClass:"col-12"},[_c('label',{attrs:{"for":"i3"}},[_vm._v("Meta Description")]),_c('ckeditor',{attrs:{"editor":_vm.editor},model:{value:(_vm.form.meta_desc),callback:function ($$v) {_vm.$set(_vm.form, "meta_desc", $$v)},expression:"form.meta_desc"}})],1),_c('b-form-group',{staticClass:"col-12"},[_c('div',{staticStyle:{"display":"flex","gap":"10px","padding":"2px 0"}},[_c('b-form-checkbox',{model:{value:(_vm.form.visible_on_doctor_profile),callback:function ($$v) {_vm.$set(_vm.form, "visible_on_doctor_profile", $$v)},expression:"form.visible_on_doctor_profile"}},[_vm._v("Visible to doctor profile only")])],1)])],1)]),(
                (_vm.form.action_type == 'post_an_update' &&
                  (_vm.$route.name == 'add-post' ||
                    _vm.$route.name == 'edit-post')) ||
                (_vm.form.action_type == 'presentation' &&
                  _vm.$route.name == 'edit-post' &&
                  _vm.showFileName)
              )?_c('tab-content',{attrs:{"title":"Post Preview","icon":"mdi mdi-post"}},[(_vm.form.member_tagging)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-4 mb-3",staticStyle:{"display":"grid","justify-content":"center"}},[_c('h5',{staticClass:"text-center"},[_vm._v(" Preview of "),(_vm.form.action_type == 'post_an_update')?_c('span',{staticStyle:{"font-weight":"900"}},[_vm._v("Post an Update")]):_vm._e(),(_vm.form.action_type == 'presentation')?_c('span',{staticStyle:{"font-weight":"900"}},[_vm._v(" Presentation ")]):_vm._e()]),_c('div',{staticClass:"post_grid"},[_c('div',{staticClass:"post_head_grid"},[_c('img',{staticStyle:{"justify-self":"center"},attrs:{"src":_vm.form.member_tagging.profile_image ||
                          require('@/assets/images/only_doctor.png'),"height":"50","width":"50"}}),_c('div',{staticClass:"post_member_grid"},[_c('div',{staticClass:"handle_line_clamp"},[(_vm.form.member_tagging.fnameAndLname)?_c('span',[_vm._v(" "+_vm._s(_vm.form.member_tagging.fnameAndLname.replace( /[-0-9]/g, "" )))]):_c('span',[_vm._v("[Member Name]")])]),_c('div',{staticClass:"handle_line_clamp"},[(_vm.form.member_tagging.city)?_c('span',[_vm._v(_vm._s(_vm.form.member_tagging.city))]):_c('span',[_vm._v("[City]")]),(
                              _vm.form.member_tagging.speciality_id &&
                              _vm.member_speciality
                            )?_c('span',[_vm._v(" - "+_vm._s(_vm.member_speciality))]):_c('span',[_vm._v(" - [Speciality Name]")])])])]),_c('div',{staticClass:"px-4"},[(_vm.form.title)?_c('span',{staticClass:"dc_desc"},[_vm._v(_vm._s(_vm.form.title))]):_c('span',[_vm._v("[Title]")])]),(_vm.form.communities_selected.length > 0)?_c('div',{staticClass:"px-4 post_community"},[_vm._l((_vm.form.communities_selected),function(item,index){return [_c('span',{key:index,staticClass:"mr-1"},[_vm._v(" #"+_vm._s(item.title)+" ")])]})],2):_vm._e(),(_vm.form.url_link)?_c('div',{staticClass:"px-4"},[_c('a',{staticClass:"post_url",attrs:{"href":_vm.form.url_link,"target":"blank"}},[_vm._v(_vm._s(_vm.form.url_link))])]):_vm._e(),_c('div',{class:`${
                        _vm.images_videos ? 'mt-2' : 'mt-0'
                      } position-relative`},[_c('span',{staticClass:"post_type mx-2"},[_vm._v("Post")]),(_vm.images_videos.length > 0)?_c('carousel',{attrs:{"per-page":1,"mouse-drag":false,"navigationEnabled":true,"paginationEnabled":false}},_vm._l((_vm.images_videos),function(item,index){return _c('slide',{key:index},[(item.media_type == 'image')?_c('img',{key:index,attrs:{"width":"500","height":"380","src":item.image_name,"alt":"image slot"}}):_vm._e(),(item.media_type == 'video')?_c('video',{key:index,attrs:{"width":"500","height":"380","controls":""}},[_c('source',{attrs:{"src":item.image_name,"type":"video/mp4"}})]):_vm._e()])}),1):_vm._e(),(_vm.form.post_meta_image)?_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.form.post_meta_image,"height":"100%","width":"500"}}):_vm._e()],1),(_vm.form.post_meta_title)?_c('span',{staticClass:"px-2 post_community"},[_vm._v(_vm._s(_vm.form.post_meta_title))]):_vm._e(),(_vm.form.post_meta_desc)?_c('span',{staticClass:"px-2 post_meta_desc"},[_vm._v(_vm._s(_vm.form.post_meta_desc))]):_vm._e(),_c('div',{class:`dc_useful_interaction d-flex justify-content-between align-items-center px-3 pb-3 ${
                        _vm.images_videos.length > 0 || _vm.form.post_meta_desc
                          ? 'pt-0'
                          : 'pt-3'
                      }`},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/usefullInteraction/usefull.png")}}),_c('span',{staticClass:"ml-2"},[_vm._v("Useful")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/usefullInteraction/save.png")}}),_c('span',{staticClass:"ml-2"},[_vm._v("Save")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/usefullInteraction/comment.png")}}),_c('span',{staticClass:"ml-2"},[_vm._v("Comment")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/usefullInteraction/interaction.png")}}),_c('span',{staticClass:"ml-2"},[_vm._v("Share")])])])])])]):_vm._e()]):_vm._e()],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }