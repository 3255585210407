import {
    campaign_speciality_data
  } from "../../js/path";
  
  export default {
    data() {
      return {
        pageNo: null,
        table_header: [],
        campaign_spec_id: null,
        fields: [
          {
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: "name",
            label: "Name"
          },
          {
            key: "marathi_name",
            label: "Marathi Name"
          },
          {
            key: "hindi_name",
            label: "Hindi Name"
          },
          {
            key: "is_active",
            label: "Status",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        params: "",
        filter: null,
        filterOn: [],
        tableData: [],
        sortBy: "id",
        sortDesc: true,
        key: 0,
        activeTab: "all",
        currentPage: 1,
        form: {
          key: "", 
          name: "", 
          marathi_name: "", 
          hindi_name: "", 
          color: "",
          color2: "", 
          icon: "", 
          border_icon: "",
          border_icon2: "", 
          ver_background_image: "", 
          hor_background_image: "", 
          card2_background_image: "",
          card3_background_image: "",
          card4_background_image: "",
          is_active: 0
        },
        icon: "",
        border_icon: "",
        border_icon2: "",
        ver_background_image: "",
        hor_background_image: "",
        card2_background_image: "",
        card3_background_image: "",
        card4_background_image: "",
        icon_img_url: "",
        border_icon_img_url: "",
        border_icon2_img_url: "",
        ver_background_image_img_url: "",
        hor_background_image_img_url: "",
        card2_background_image_img_url: "",
        card3_background_image_img_url: "",
        card4_background_image_img_url: "",
        edit: {
          icon_img_url: null,
          border_icon_img_url: null,
          border_icon2_img_url: null,
          ver_background_image_img_url: null,
          hor_background_image_img_url: null,
          card2_background_image_img_url: null,
          card3_background_image_img_url: null,
          card4_background_image_img_url: null,
        },
      };
    },
    computed: {
      
    },
    methods: {
      readFile(e, txt) {
        if (txt == "icon") {
          this.icon = e.target.files[0];
          if (this.icon.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.icon.reset();
            this.icon_img_url = "";
            this.edit.icon_img_url = "";
            this.icon = "";
            return false;
          } else {
            this.icon_img_url = URL.createObjectURL(this.icon);
            this.edit.icon_img_url = "";
            return true;
          }
        }
        if (txt == "border_icon") {
          this.border_icon = e.target.files[0];
          if (this.border_icon.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.border_icon.reset();
            this.border_icon_img_url = "";
            this.edit.border_icon_img_url = "";
            this.border_icon = "";
            return false;
          } else {
            this.border_icon_img_url = URL.createObjectURL(this.border_icon);
            this.edit.border_icon_img_url = "";
            return true;
          }
        }
        if (txt == "border_icon2") {
          this.border_icon2 = e.target.files[0];
          if (this.border_icon2.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.border_icon2.reset();
            this.border_icon2_img_url = "";
            this.edit.border_icon2_img_url = "";
            this.border_icon2 = "";
            return false;
          } else {
            this.border_icon2_img_url = URL.createObjectURL(this.border_icon2);
            this.edit.border_icon2_img_url = "";
            return true;
          }
        }
        if (txt == "ver_background_image") {
          this.ver_background_image = e.target.files[0];
          if (this.ver_background_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.ver_background_image.reset();
            this.ver_background_image_img_url = "";
            this.edit.ver_background_image_img_url = "";
            this.ver_background_image = "";
            return false;
          } else {
            this.ver_background_image_img_url = URL.createObjectURL(this.ver_background_image);
            this.edit.ver_background_image_img_url = "";
            return true;
          }
        }
        if (txt == "hor_background_image") {
          this.hor_background_image = e.target.files[0];
          if (this.hor_background_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.hor_background_image.reset();
            this.hor_background_image_img_url = "";
            this.edit.hor_background_image_img_url = "";
            this.hor_background_image = "";
            return false;
          } else {
            this.hor_background_image_img_url = URL.createObjectURL(this.hor_background_image);
            this.edit.hor_background_image_img_url = "";
            return true;
          }
        }
        if (txt == "card2_background_image") {
          this.card2_background_image = e.target.files[0];
          if (this.card2_background_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.card2_background_image.reset();
            this.card2_background_image_img_url = "";
            this.edit.card2_background_image_img_url = "";
            this.card2_background_image = "";
            return false;
          } else {
            this.card2_background_image_img_url = URL.createObjectURL(this.card2_background_image);
            this.edit.card2_background_image_img_url = "";
            return true;
          }
        }
        if (txt == "card3_background_image") {
          this.card3_background_image = e.target.files[0];
          if (this.card3_background_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.card3_background_image.reset();
            this.card3_background_image_img_url = "";
            this.edit.card3_background_image_img_url = "";
            this.card3_background_image = "";
            return false;
          } else {
            this.card3_background_image_img_url = URL.createObjectURL(this.card3_background_image);
            this.edit.card3_background_image_img_url = "";
            return true;
          }
        }
        if (txt == "card4_background_image") {
          this.card4_background_image = e.target.files[0];
          if (this.card4_background_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.card4_background_image.reset();
            this.card4_background_image_img_url = "";
            this.edit.card4_background_image_img_url = "";
            this.card4_background_image = "";
            return false;
          } else {
            this.card4_background_image_img_url = URL.createObjectURL(this.card4_background_image);
            this.edit.card4_background_image_img_url = "";
            return true;
          }
        } 
      },
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      searchFor() {
        if (this.filter.length > 0) {
          this.fetchData(this.activeTab);
        } else {
          this.fetchData(this.activeTab);
        }
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            campaign_speciality_data.mrAppcampaignSpecialityDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = campaign_speciality_data.mrAppcampaignSpecialityDataUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            campaign_speciality_data.mrAppcampaignSpecialityDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = campaign_speciality_data.mrAppcampaignSpecialityDataUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            campaign_speciality_data.mrAppcampaignSpecialityDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = campaign_speciality_data.mrAppcampaignSpecialityDataUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async updateStatus(id) {
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = campaign_speciality_data.mrAppcampaignSpecialityDataupdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            is_active: !this.tableData.data[index].is_active,
          });
          if (data.status) {
            const responseData = data.response;
            this.tableData.data[index] = responseData;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
      },
      async fetchCampaignSpecialityData(id) {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = campaign_speciality_data.mrAppcampaignSpecialityDataUrl + "/" + id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.name != "" && responseData.name != null) {
              this.form.name = responseData.name;
            }
            if (responseData.key != "" && responseData.key != null) {
              this.form.key = responseData.key;
            }
            if (
              responseData.marathi_name != "" &&
              responseData.marathi_name != null
            ) {
              this.form.marathi_name = responseData.marathi_name;
            }
            if (
              responseData.hindi_name != "" &&
              responseData.hindi_name != null
            ) {
              this.form.hindi_name = responseData.hindi_name;
            }
            if (responseData.is_active) {
              this.form.is_active = true;
            }
            if (responseData.icon != "" && responseData.icon != null) {
              this.edit.icon_img_url = responseData.icon ? responseData.icon : "";
            }
            if (responseData.border_icon != "" && responseData.border_icon != null) {
              this.edit.border_icon_img_url = responseData.border_icon ? responseData.border_icon : "";
            }
            if (responseData.border_icon2 != "" && responseData.border_icon2 != null) {
              this.edit.border_icon2_img_url = responseData.border_icon2 ? responseData.border_icon2 : "";
            }
            if (responseData.ver_background_image != "" && responseData.ver_background_image != null) {
              this.edit.ver_background_image_img_url = responseData.ver_background_image ? responseData.ver_background_image : "";
            }
            if (responseData.hor_background_image != "" && responseData.hor_background_image != null) {
              this.edit.hor_background_image_img_url = responseData.hor_background_image ? responseData.hor_background_image : "";
            }
            if (responseData.card2_background_image != "" && responseData.card2_background_image != null) {
              this.edit.card2_background_image_img_url = responseData.card2_background_image ? responseData.card2_background_image : "";
            }
            if (responseData.card3_background_image != "" && responseData.card3_background_image != null) {
              this.edit.card3_background_image_img_url = responseData.card3_background_image ? responseData.card3_background_image : "";
            }
            if (responseData.card4_background_image != "" && responseData.card4_background_image != null) {
              this.edit.card4_background_image_img_url = responseData.card4_background_image ? responseData.card4_background_image : "";
            }
            if (responseData.color != "" && responseData.color != null) {
              this.form.color = responseData.color ? responseData.color : "";
            }
            if (responseData.color2 != "" && responseData.color2 != null) {
              this.form.color2 = responseData.color2 ? responseData.color2 : "";
            }
            this.campaign_spec_id = responseData.id;
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Fetching error!",
          });
        }
        this.$store.commit("toast/updateStatus", false);
      },
      async deleteCampaignSpecialityData(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = campaign_speciality_data.mrAppcampaignSpecialityDataUrl + "/" + id;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async restoreCampaignSpecialityData(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = campaign_speciality_data.restoremrAppSpecialityData + "/" + id;
          const data = await this.postRequest(url, {});
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            // window.scrollTo({
            //    top: 0,
            //    behavior: "smooth",
            // });
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          }
          this.$store.commit("loader/updateStatus", true);
          let url = campaign_speciality_data.mrAppcampaignSpecialityDataUrl;
          if (this.$route.name == "edit-campaign-speciality-data") {
            url = campaign_speciality_data.mrAppcampaignSpecialityDataUrl + "/" + this.campaign_spec_id;
          }
          let dataAppend = new FormData();
  
          if (this.form.is_active) {
            dataAppend.append("is_active", 1);
          } else {
            dataAppend.append("is_active", 0);
          }
  
          if (this.icon) {
            dataAppend.append("icon", this.icon);
          }

          if (this.border_icon) {
            dataAppend.append("border_icon", this.border_icon);
          }

          if (this.border_icon2) {
            dataAppend.append("border_icon2", this.border_icon2);
          }

          if (this.ver_background_image) {
            dataAppend.append("ver_background_image", this.ver_background_image);
          }

          if (this.hor_background_image) {
            dataAppend.append("hor_background_image", this.hor_background_image);
          }

          if (this.card2_background_image) {
            dataAppend.append("card2_background_image", this.card2_background_image);
          }

          if (this.card3_background_image) {
            dataAppend.append("card3_background_image", this.card3_background_image);
          }

          if (this.card4_background_image) {
            dataAppend.append("card4_background_image", this.card4_background_image);
          }
  
          for (var key in this.form) {
            if (
              key != "is_active" &&
              key != "icon" &&
              key != "border_icon" &&
              key != "border_icon2" &&
              key != "ver_background_image" &&
              key != "hor_background_image" &&
              key != "card2_background_image" &&
              key != "card3_background_image" &&
              key != "card4_background_image"
            ) {
              dataAppend.append(key, this.form[key]);
            }
          }
  
          if (this.$route.name == "edit-campaign-speciality-data") {
            dataAppend.append("_method", "put");
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/field-rep-app/campaign-speciality-data");
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
    //   tabActive() {
    //     if (this.activeTab == "trash") {
    //        if (this.table_header.includes("delete")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           this.fields[index] = {
    //              key: "restore",
    //           };
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "delete"
    //           );
    //           this.table_header[table_index] = "restore";
    //        } else {
    //           this.table_header.push("restore");
    //           this.fields.push({
    //              key: "restore",
    //           });
    //        }
  
    //        if (!this.can("restore-campaigns")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "restore"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "restore"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        }
  
    //        let index = this.fields.findIndex((item) => item.key == "edit");
    //        if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "edit"
    //           );
    //           this.table_header.splice(table_index, 1);
    //        }
    //     } else {
    //        if (this.table_header.includes("restore")) {
    //           if (!this.can("edit-campaigns")) {
    //              this.fields[6] = {
    //                 key: "delete",
    //              };
    //           } else {
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
  
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "restore"
    //           );
    //           this.table_header[table_index] = "delete";
    //        }
  
    //        if (!this.can("edit-campaigns")) {
    //           let index = this.fields.findIndex((item) => item.key == "edit");
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "edit"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("edit")) {
    //              this.table_header.push("edit");
    //              this.fields[6] = {
    //                 key: "edit",
    //              };
    //           }
    //        }
  
    //        if (!this.can("delete-campaigns")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "delete"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("delete")) {
    //              this.table_header.push("delete");
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
    //        }
    //     }
    //  },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      // activeTab(v) {
        // if (v) this.tabActive();
      // },
      "form.content_type"(v) {
        if (v == "pop_up") {
          this.form.ad_position = 1;
        }
      },
    },
    created() {
      if (
        this.$route.name == "add-campaign-speciality-data" ||
        this.$route.name == "edit-campaign-speciality-data"
      ) {
        if (this.$route.name == "edit-campaign-speciality-data") {
          this.fetchCampaignSpecialityData(this.$route.params.id);
        }
      } else {
        this.fetchData(this.activeTab);
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      // this.tabActive();
    },
  };
  